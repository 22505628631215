import { graphql, Link, useStaticQuery } from "gatsby"
import React, { useState } from "react"
import MenuMobile from "./MenuMobile"
import { FaBars } from "react-icons/fa"

import "./Header.css"

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const { site } = useStaticQuery(graphql`
    query {
      site {
        data: siteMetadata {
          menu {
            name
            to
          }
        }
      }
    }
  `)

  return (
    <div className="nav bg-white">
      <div className="container pt-6 pb-6 md:pt-6">
        <div className="flex justify-between items-center">
          <Link to="/">
            <h1 className="text-lg leading-tight font-bold tracking-tight text-green-800 lg:text-2xl">Luz Amira Nutrición</h1>
          </Link>

          <button
            className="md:hidden"
            onClick={() => setIsMenuOpen(true)}
            aria-label="Open Menu"
          >
            <FaBars className="h-6 w-auto text-gray-900 fill-current" />
          </button>

          <div className="hidden md:block">
            {site.data.menu.map((link, key) => (
              <Link
                key={`menu_desktop_link${key}`}
                className="ml-5 sm:ml-5 text-sm sm:text-base font-medium px-px border-b-2 pb-2 border-transparent text-gray-700 hover:text-green-600 hover:border-green-200 transition duration-150 ease-in-out"
                activeClassName="border-green-600 text-gray-600 hover:border-green-600"
                to={link.to}
              >
                {link.name}
              </Link>
            ))}
          </div>
        </div>
        <MenuMobile
          isOpen={isMenuOpen}
          setIsOpen={setIsMenuOpen}
          links={site.data.menu}
        />
      </div>
    </div>
  )
}

export default Header
